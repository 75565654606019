import PropTypes from 'prop-types';
import ShopTheLookDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/ShopTheLookDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const ShopTheLookModule = ({ isGrid = false, ...rest }) => {
    const [css] = useStyletron();

    return (
        <section
            className={css({
                width: '100%',
                height: '100%',
                maxHeight: 'calc(100vh - var(--height-header))',
                marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)',
                padding: '0 var(--padding-content-shop-the-look)',
                aspectRatio: 'var(--ratio-module-shop-the-look)',
            })}
        >
            <ShopTheLookDataTransformerBlock {...rest} />
        </section>
    );
};

ShopTheLookModule.propTypes = {
    isGrid: PropTypes.bool,
};

export default ShopTheLookModule;
